import React, { Component } from "react";
import PropTypes from "prop-types";
import { CenteredSpinner } from "@stacc/flow-ui-components";

import fileService from "../services/files";
import ImageViewer from "./documentsViewer/ImageViewer";
import PdfViewer from "./documentsViewer/PdfViewer";

class FileDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            file: null
        };
    }

    componentDidMount() {
        const { fileId } = this.props;
        this.setState({ isLoading: true });

        fileService.getFile(fileId).then(result => {
            if (result) {
                this.setState({
                    file: { fileId, type: result.type, file: result.file },
                    isLoading: false
                });
            } else {
                this.setState({ file: null, isLoading: false });
            }
        });
    }

    render() {
        const { file, isLoading } = this.state;

        if (isLoading) {
            return <CenteredSpinner />;
        }

        if (!file) {
            return null;
        }

        if (file.type.includes("image")) {
            return <ImageViewer file={file.file} type={file.type} />;
        }

        if (file.type.includes("pdf")) {
            return <PdfViewer file={file.file} fileId={file.id} />;
        }

        return null;
    }
}

FileDisplay.propTypes = {
    fileId: PropTypes.string.isRequired
};

export default FileDisplay;
