import React, {Component} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Colors, FontStyles} from "@stacc/flow-ui-components";
import {formatDateTime} from "../utils/dateFormatter";
import FileItem from "./FileItem";

class Summary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            variables: undefined,
            variablesMessage: ""
        };
        // fetch variables from the Camunda API.
        if (props.caseData._meta.processInstanceId) {
            fetch(`/engine-rest/history/variable-instance?processInstanceId=${props.caseData._meta.processInstanceId}&sortBy=variableName&sortOrder=asc`)
                .then(response => response.json())
                .then(data => {
                    this.setState({variables: data})
                })
                .catch
                (error => {
                    this.setState({variables: undefined, variablesMessage: error.message});
                });
        }
    }

    render() {
        const caseData = this.props.caseData;
        if (!caseData.data) {
            return (<div/>);
        }
        switch (caseData.flowDefinitionId) {
            case "company-customer-declaration":
            case "corporate-periodic-renewal":
            case "corporate-periodic-renewal2": {
                return this.renderDeclarationOrPeriodicRenewalCase();
            }
            case "cleanup-files-and-flows": {
                return this.renderCleanupCase();
            }
            default:
                throw new Error("Unsupported flowDefinitionId " + caseData.flowDefinitionId);
        }
    }

    /**Display the process variables if the Camunda API is available at localhost:8080*/
    renderVariables() {
        if (this.state.variables) {
            return (
                <div style={{marginLeft: 10, marginRight: -30}}>
                    <Title2>Variables</Title2>
                    <VariablesTable>
                        <thead>
                        <tr>
                            <VariablesTableTh>Name</VariablesTableTh>
                            <VariablesTableTh>Value</VariablesTableTh>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.variables
                                .filter(variable => variable.name !== '_staccMeta')
                                .map(variable => (
                                    <tr>
                                        <VariablesTableTd key={variable.name}>{variable.name}</VariablesTableTd>
                                        <VariablesTableTd>{JSON.stringify(variable.value)}</VariablesTableTd>
                                    </tr>))
                        }
                        </tbody>
                    </VariablesTable>
                </div>
            );
        }
    }

    renderTasksWithVariables() {
        const caseData = this.props.caseData;
        const tasksWithVariables = caseData.tasks?.filter(task => task.hasOwnProperty("variables"));
        if (tasksWithVariables) {
            return (<TaskVariables>
                <Title>Tasks with variables</Title>
                <List>
                    {tasksWithVariables.map(task =>
                        <li key={task.taskId}>
                            <ListTitle>{task.taskType}</ListTitle>
                            <pre>{JSON.stringify(task.variables, undefined, 2)}</pre>
                        </li>)}
                </List>
            </TaskVariables>);
        }
    }

    renderCleanupCase() {
        const caseData = this.props.caseData;
        const created = caseData.createdAt
            ? formatDateTime(new Date(caseData.createdAt))
            : false;
        return (<div>
                <CaseSummary>
                    <EntryFrame>
                        <Title> flowId </Title>
                        <p>{caseData.flowId}</p>
                    </EntryFrame>
                    <EntryFrame>
                        <Title>{"Opprettet"}</Title>
                        <SummaryP>{created}</SummaryP>
                    </EntryFrame>
                </CaseSummary>
            </div>
        )
    }

    renderDeclarationOrPeriodicRenewalCase() {
        const caseData = this.props.caseData;
        const t = this.props.t;
        const referenceID = caseData.referenceId ? caseData.referenceId : false;
        const flowDefinitionId = caseData.flowDefinitionId ? caseData.flowDefinitionId : false;
        const company = caseData.data.company;
        const isDeclarationFlow = caseData.flowDefinitionId === "company-customer-declaration";

        const companyName = isDeclarationFlow ?
            (company.name.value ? company.name.value : false) :
            (caseData.data.companyName ? caseData.data.companyName :
                    (caseData.data.customer?.customerInformation.name ? caseData.data.customer.customerInformation.name : false)
            );
        const organizationNumber = isDeclarationFlow ?
            (company.organizationNumber.value ? company.organizationNumber.value : false) :
            (caseData.data.companyOrgNumber ? caseData.data.companyOrgNumber : false);
        const bank = caseData.data.bank.orgId ? caseData.data.bank.orgId : false;
        const updated = caseData.updatedAt ? formatDateTime(new Date(caseData.updatedAt)) : false;
        const created = caseData.createdAt ? formatDateTime(new Date(caseData.createdAt)) : false;
        const processType = isDeclarationFlow ?
            (caseData.data.process.type === 'renewal' ?
                'Fornyelse' :
                (caseData.data.process.companyUnderEstablishment ?
                    'Onboarding SUS' :
                    'Onboarding')) : flowDefinitionId;

        let taskItems;
        if (isDeclarationFlow) {
            taskItems = [];
            const attachments = caseData.data.declarationForm && caseData.data.declarationForm.attachments
                ? caseData.data.declarationForm.attachments
                : false;
            const file = caseData.data.documentId ? caseData.data.documentId : false;

            if (!!attachments) {
                attachments.map(task => {
                    return taskItems.push(
                        <li>
                            <FileItem {...task} type={"attachment"}/>
                        </li>
                    );
                });
            }

            if (!!file) {
                taskItems.push(
                    <li>
                        <FileItem {...caseData.data} type={"file"}/>
                    </li>
                );
            }
        }

        return (<>
                <CaseSummary>
                    <EntryFrame>
                        <Title>{"Opprettet"}</Title>
                        <SummaryP>{created} </SummaryP>
                    </EntryFrame>
                    <EntryFrame>
                        <Title>{"Sist oppdatert"}</Title>
                        <SummaryP>{updated}</SummaryP>
                    </EntryFrame>
                    <EntryFrame>
                        <Title>{"Prosess"}</Title>
                        <SummaryP>{t(processType)}</SummaryP>
                    </EntryFrame>
                    <EntryFrame>
                        <Title>{"Referanse"}</Title>
                        <SummaryP>{`${referenceID}`}</SummaryP>
                    </EntryFrame>
                </CaseSummary>
                <CaseSummary>
                    <EntryFrame>
                        <Title> {"Bank"}</Title>
                        <SummaryP>{`${bank}`}</SummaryP>
                    </EntryFrame>
                    <EntryFrame>
                        <Title>{"Selskap"}</Title>
                        <SummaryP>{`${companyName}`}</SummaryP>
                    </EntryFrame>
                    <EntryFrame>
                        <Title>{"Selskap OrgNr"}</Title>
                        <SummaryP>{`${organizationNumber}`}</SummaryP>
                    </EntryFrame>
                </CaseSummary>
                {this.renderVariables()}
                {this.renderTasksWithVariables()}
                {taskItems && taskItems.length > 0 && (
                    <div style={{marginBottom: "30px"}}>
                        <ListTitle>{"Filer"}</ListTitle>
                        <List>{taskItems}</List>
                    </div>
                )}
            </>
        );
    }
}


const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
        border-bottom: 1px solid ${Colors.Grey3};
    }

    li:first-of-type {
        border-top: 1px solid ${Colors.Grey3};
    }
`;

const ListTitle = styled.p`
    ${FontStyles.Small};
    margin: 0;
    padding-left: 15px;
    padding-bottom: 15px;
`;

const CaseSummary = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-columns: minmax(200px, auto);
    grid-auto-rows: minmax(20px, auto);
    width: 100%;
    min-width: 550px;
    min-height: 105px;
    justify-items: start;
    padding: 10px;
    margin: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #f7fbff;
    border: 1px solid #e6e4e3;
    border-radius: 4px;
`;

const TaskVariables = styled.div`
    position: relative;
    width: 100%;
    min-width: 550px;
    min-height: 105px;
    justify-items: start;
    padding: 10px;
    margin: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #f7fbff;
    border: 1px solid #e6e4e3;
    border-radius: 4px;
`;

const EntryFrame = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-top: 30px;
    margin-bottom: 0;
`;
const Title = styled.p`
    ::first-letter {
        text-transform: capitalize;
    }

    color: #bbb;
    font-size: 12px;
    margin-bottom: 8px
`;

const Title2 = styled.p`
    ${FontStyles.LargeStrong};
`;

const VariablesTable = styled.table`
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;

    tr:nth-child(even) {
        background-color: #f7fbff;
    }
`;

const VariablesTableTd = styled.td`
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
`;

const VariablesTableTh = styled.th`
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
`;
const SummaryP = styled.p`
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
`;

Summary.propTypes = {
    t: PropTypes.func.isRequired,
    caseData: PropTypes.shape({
        data: PropTypes.shape({})
    }).isRequired
};

export default withTranslation("inbox")(Summary);
