import React, {Component} from "react";

import styled from "styled-components";
import {FontStyles, Icons, Modal, PrimaryButton} from "@stacc/flow-ui-components";

import FileDisplay from "./FileDisplay";
import fileService from "../services/files";
import downloadLink from "../helpers/downloadLink";

class FileItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayTask: false
        };
    }

    onDownloadClick() {
        const {type} = this.props;

        const fileId =
            type === "attachment" ? this.props.storeId : this.props.documentId;

        fileService.getFileMetadata(fileId).then(response => {
            if (!response) {
                console.error("Error getting file meta data");
                return;
            }

            const metadata = response.data;
            const {filename} = metadata;

            fileService
                .getFile(fileId)
                .then(result => downloadLink(result.file, filename));
        });
    }

    render() {
        const {displayTask} = this.state;

        const {type} = this.props;

        const title =
            type === "attachment" ? this.props.fileName : this.props.documentId;

        const fileId =
            type === "attachment" ? this.props.storeId : this.props.documentId;

        return (
            <div>
                <TaskContainer>
                    <TaskDescription>
                        <Icons.File/>
                        <p>{title}</p>
                        <p className="small">{type}</p>
                    </TaskDescription>
                    <ButtonGroup>
                        <PrimaryButton
                            onClick={() =>
                                this.setState({displayTask: !displayTask})
                            }
                        >
                            {"Vis Fil"}
                        </PrimaryButton>
                        <PrimaryButton onClick={() => this.onDownloadClick()}>
                            {"Last Ned"}
                        </PrimaryButton>
                    </ButtonGroup>
                </TaskContainer>
                {this.state.displayTask && (
                    <Modal
                        title={title}
                        onClose={() => this.setState({displayTask: false})}
                    >
                        <FileDisplay fileId={fileId}/>
                    </Modal>
                )}
            </div>
        );
    }
}

const TaskContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    ${FontStyles.LargeStrong};
`;

const ButtonGroup = styled.div`
    button {
        margin-left: 10px;
    }
`;

const TaskDescription = styled.div`
    display: flex;
    align-items: center;
    min-height: 35px;

    p {
        margin: 0;
        padding-left: 20px;

        &.small {
            ${FontStyles.Small};
        }
    }
`;

export default FileItem;
