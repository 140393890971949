import React, {Component} from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import styled from "styled-components";
import {Link} from 'react-router-dom';
import {
    FontStyles,
    Icons,
    Modal,
    PrimaryButton,
    SecondaryButton,
    SecondaryNegativeButton
} from "@stacc/flow-ui-components";

import ReactJson from 'react-json-view'

class FailedTask extends Component {
    constructor(props) {
        super(props);

        this.state = {
            disabled: this.props.disabled,
            actionTitle: this.props.actionTitle,
            path: this.props.path,
            title: this.props.title,
            isModalOpen: false,
        };
    }

    showErrorDetails() {
        this.setState({isModalOpen: true});
    }

    closeModal() {
        this.setState({isModalOpen: false});
    }

    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    render() {
        const errorMessage = this.props.errorMessage;
        const translate = this.props.t;
        if (!errorMessage) {
            return (<div/>);
        }

        const jsonString = errorMessage.substring(errorMessage.indexOf("{"), errorMessage.indexOf(" at /"));
        const containsJson = this.isJsonString(jsonString);
        const error = {
            errorJson: containsJson ? JSON.parse(jsonString) : undefined,
            errorLocation: containsJson ? errorMessage.substring(errorMessage.indexOf(" at /") - 1) : undefined,
            errorText: errorMessage
        };

        return (
            <TaskContainer>
                <TaskDescription>
                    <Icons.ErrorCircle/>
                    <p>{this.state.title}</p>
                </TaskDescription>
                <ButtonsContainer>
                    <Link style={{paddingRight: 15}} to={"#"}>
                        <SecondaryNegativeButton onClick={() => this.showErrorDetails()}>
                            {translate("Error details")}
                        </SecondaryNegativeButton>
                    </Link>
                    {this.state.disabled ? (
                        <PrimaryButton disabled>
                            {this.state.actionTitle}
                        </PrimaryButton>
                    ) : (
                        <Link to={this.state.path}>
                            <PrimaryButton>
                                {this.state.actionTitle}
                            </PrimaryButton>
                        </Link>
                    )}
                </ButtonsContainer>
                <ErrorDetails
                    isOpen={this.state.isModalOpen}
                    title={this.state.title}
                    onClose={() => this.closeModal()}
                    error={error}
                    translate={this.props.t}
                />
            </TaskContainer>
        );
    }
}

const ErrorDetails = ({isOpen, title, onClose, error, translate}) => {
    if (isOpen !== true) {
        return null;
    }
    return (
        <Modal title={title} size="large" onClose={onClose} closeOnBackdrop={false}>
            <ModalBody>
                <ModalContent>
                    {error.errorJson ?
                        (<div>
                            <div>
                                <h3>{translate("Error details")}: </h3>
                                <ReactJson src={error.errorJson} displayDataTypes={false} name={false}
                                           collapseStringsAfterLength={150}/>
                            </div>
                            <div>
                                <h3>{translate("Location details")}: </h3>
                                <pre>{error.errorLocation}</pre>
                            </div>
                        </div>) :
                        (<div>
                            <h3>{translate("Error details")}: </h3>
                            <pre>{error.errorText}</pre>
                        </div>)
                    }

                    <ButtonGroup>
                        <SecondaryButton title={translate("Close")} onClick={onClose}>
                            {translate("Close")}
                        </SecondaryButton>
                    </ButtonGroup>
                </ModalContent>
            </ModalBody>
        </Modal>
    );
};

const ButtonGroup = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
`;

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const ModalContent = styled.div`
    height: 100%;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const TaskContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    ${FontStyles.LargeStrong};
`;

const TaskDescription = styled.div`
    display: flex;
    align-items: center;
    min-height: 35px;

    p {
        margin: 0;
        padding-left: 20px;
    }
`;
const ButtonsContainer = styled.div`
    display: flex;
    width: auto;
`;

FailedTask.propTypes = {
    t: PropTypes.func.isRequired,
    errorMessage: PropTypes.string.isRequired,
    taskType: PropTypes.string.isRequired,
    taskCategory: PropTypes.string.isRequired,
};

export default withTranslation("inbox")(FailedTask);
