import axios from "axios";

const getFile = async (fileId) => {
  const filePath = `/api/files/${fileId}`;

  try {
    const res = await axios({
      method: "get",
      url: filePath,
      responseType: "arraybuffer"
    });

    const type = res.headers["content-type"];
    if (type.includes("image") || type.includes("pdf")) {
      return { file: res.data, type };
    }

    const error = new Error(`Error while getting file. Unexpected content-type: ${type}`);
    console.error(error);
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getFileMetadata = async (fileId) => {
  const filePath = `/api/files/${fileId}/meta`;

  try {
    return await axios.get(filePath);
  } catch (error) {
    console.error(error);
    return null;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getFile,
  getFileMetadata
};
