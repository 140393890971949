import React from "react";
import {createRoot} from 'react-dom/client';
import AuthenticationIcon from "./customer/plugins/AuthenticationIcon";
import {APPS, CaseManager} from "@flow/case-manager";
import * as customerSetup from "./customer";
import * as serviceWorker from "./serviceWorker";
import inboxTranslates from "./translates/inbox.json";
import searchTranslates from "./translates/search.json";
import "./index.css";
import "@stacc/flow-ui-style/dist/reset.css";
import "@stacc/flow-ui-style/dist/global.css";

const Application =
  <CaseManager
    configuration={(user) => {
      return {
        skin,
        homeUrl: "/inbox",
        apps: [APPS.Inbox, APPS.Search, APPS.Process],
        customComponents: customerSetup.CustomComponents,
        inboxConfiguration: {
          searchFields: customerSetup.SearchFields,
          startableProcesses: customerSetup.StartableProcesses,
          features: [],
        },
        processConfiguration: {
          errorListDetail: "detailed",
        },
        searchConfiguration: {
          searchFields: [
            {
              name: "Organisasjonsnummer (Selskap - Kundeerklæringer)",
              value: "data.company.organizationNumber.value",
              process: ["company-customer-declaration"],
            },
            {
              name: "Organisasjonsnummer (Periodisk Fornyelse)",
              value: "data.companyOrgNumber",
              process: ["corporate-periodic-renewal", "corporate-periodic-renewal2"],
            },
            {
              name: "Selskapsnavn",
              value: "data.company.name.value",
              process: ["company-customer-declaration"],
            },
            {
              name: "Selskapsnavn (Periodisk Fornyelse)",
              value: "data.customer.customerInformation.name",
              process: ["corporate-periodic-renewal", "corporate-periodic-renewal2"],
            },
            {
              name: "Bank OrgId",
              value: "data.bank.orgId",
              process: [
                "company-customer-declaration",
                "corporate-periodic-renewal",
                "corporate-periodic-renewal2"
              ],
            },
            {
              name: "PersonNr (PID)",
              value: "data.user.pid",
              process: ["company-customer-declaration"],
            },
            {
              name: "Process Type",
              value: "data.process.type",
              process: ["company-customer-declaration"],
              type: "select",
              options: [
                {label: "Onboarding", value: "onboarding"},
                {label: "Renewal", value: "renewal"}
              ]
            },
            {
              name: "Company Under Establishment",
              value: "data.process.companyUnderEstablishment",
              process: ["company-customer-declaration"],
              type: "select",
              options: [
                {label: "Yes", value: true},
                {label: "No", value: false}
              ]
            },
          ],
          searchResultFields: [
            {
              name: "Selskapsnavn",
              value: "data.company.name.value",
              process: ["company-customer-declaration"],
            },
            {
              name: "Selskapsnavn (Periodisk Fornyelse)",
              value: "data.customer.customerInformation.name",
              process: ["corporate-periodic-renewal", "corporate-periodic-renewal2"],
            },
            {
              name: "Organisasjonsnummer (Selskap - Kundeerklæringer)",
              value: "data.company.organizationNumber.value",
              process: ["company-customer-declaration"],
            },
            {
              name: "Organisasjonsnummer (Periodisk Fornyelse)",
              value: "data.companyOrgNumber",
              process: ["corporate-periodic-renewal", "corporate-periodic-renewal2"],
            },
            {
              name: "Bank OrgId",
              value: "data.bank.orgId",
              mapping: (value) => value, //otherwise, it is converted to the date format, e.g. 01.01.9057
            },
            {
              name: "PersonNr",
              value: "data.user.pid",
              process: ["company-customer-declaration"],
            },
            {
              name: "Kunde",
              value: "data.customer.isCustomer",
              mapping: (value) =>
                !!value === true ? "Ja" : "Nei",
            },
            {
              name: "Type",
              value: "data.process.type",
              process: ["company-customer-declaration"],
            },
            {
              name: "CUE",
              value: "data.process.companyUnderEstablishment",
              process: ["company-customer-declaration"],
              mapping: (value) =>
                !!value === true ? "Yes" : "No",
            },
          ],
        },
        loginConfiguration: {
          authenticationIcon: AuthenticationIcon,
        },
        translates: customTranslates,
        language: "no",
        languages: ["no", "en"],
      };
    }}
  />;

const root = createRoot(document.getElementById('root'));
root.render(Application);

const customTranslates = [
  {
    app: APPS.Inbox,
    language: "no",
    translates: inboxTranslates,
    languageFromUserEnabled: true,
  },
  {
    app: APPS.Search,
    language: "no",
    translates: searchTranslates,
    languageFromUserEnabled: true,
  },
];

const skin = {
  branding: customerSetup.Branding,
  mainLogo: customerSetup.MainLogo,
};

serviceWorker.unregister();
