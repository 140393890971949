import React from "react";
import {withTranslation} from "react-i18next";
import ReactJson from "react-json-view";

export class CaseDetails extends React.Component {
    render() {
        return (
            <div>
                <ReactJson src={this.props.data} displayDataTypes={false} name={false} collapseStringsAfterLength={200}/>
            </div>
        );
    }
}

export const CaseDetailsTranslated = withTranslation("inbox")(CaseDetails);
